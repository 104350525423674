// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
	priceFormat,
	Select,
	FormGroup,
	Input,
	Checks,
	StandardFooter,
	CardLabel,
	CardTitle,
} from '@ekhodealer/ekho-common/components';
import {
	PAYMENT_TYPES,
	INPUT_TYPES,
	TAR_PROCESS_ENUM,
	insuranceReqTypes,
	MAX_WEEKS_BEFORE_READY,
	FinancingActionItemOptions,
} from '@ekhodealer/ekho-common';
import {
	determineTarProcess,
	getInsuranceReqEnum,
	// getRelevantInsuranceObject,
} from '@ekhodealer/ekho-common/utils';
import {
	createLog,
	saveCurrentPage,
	deleteRelocation,
	performRelocation,
	deleteModal,
	updateFinancingActionItem,
	submitSurveyData,
	saveGenericData,
	genRegFees,
} from '../serverCalls';

// eslint-disable-next-line import/no-cycle
import {
	sectionKeys,
	NOTARY_FORM_TYPE,
	paymentSelectionTypes,
	creditAppDecisionTypes,
	// TYPE_OF_ORDER_ENUM, // OUTSIDE SCOPE OF THIS PROJECT
	// COMMENCEMENT_MODAL_SECTIONS, // OUTSIDE SCOPE OF THIS PROJECT
	getSectionDataMap,
	ALL_SECTION_ORDER,
	COMPLETED_PAGE_SUFFIX,
	PAYMENT_DESCRIPTION_TYPES,
	DISCOUNT_TYPES,
	SIG_WORKFLOW_TYPE,
	SIGNATURE_PAGE_NAMES,
} from '../common/data/constants';
import { auth, storage } from '../firebase';
import {
	OWNER_TYPES,
	SUPPORTED_USE_TYPES,
} from '../components/foundationalPageTypes/infoCollectPageComponents/registrationAndTitlingComponents/Util/RegAndTitlingConsts';

export function usePrevious(value) {
	const reference = useRef();
	useEffect(() => {
		reference.current = value; // assign the value of ref to the argument
	}, [value]); // this code will run when the value of 'value' changes
	return reference.current; // in the end, return the current ref value.
}

export function getSectionStartPage(currentVehicle, sectionKey, allPageNames) {
	const tarProcess = determineTarProcess(currentVehicle);
	switch (sectionKey) {
		case sectionKeys.signOff:
			return allPageNames.purchaseAgreementSignatures;

		case sectionKeys.financing:
			return allPageNames.financingChoicePage;
		case sectionKeys.registrationAndTitling:
			switch (tarProcess) {
				case TAR_PROCESS_ENUM.AUTOMATED:
					return allPageNames.regAndTitlingInfoCollect;
				case TAR_PROCESS_ENUM.ASSISTED:
					return allPageNames.regAndTitlingSelfInfoCollect;
				case TAR_PROCESS_ENUM.AUTOMATED_TITLING:
					return allPageNames.automatedTitlingInfoCollect;
				case TAR_PROCESS_ENUM.ASSISTED_TITLING:
					return allPageNames.regAndTitlingSelfInfoCollect;
				case TAR_PROCESS_ENUM.NONE:
					return allPageNames.regAndTitlingSelfInfoCollect;
				default:
					return allPageNames.regAndTitlingSelfInfoCollect;
			}
		case sectionKeys.insurance:
			return allPageNames.insuranceChoicePage;
		case sectionKeys.deliveryDetails:
			return allPageNames.deliveryDetailsChoicePage;
		case sectionKeys.documentsAndSignatures:
			return allPageNames.documentsAndSignaturesStartingPage;
		case sectionKeys.payment:
			return currentVehicle.paymentInfo.paymentSelection ===
				paymentSelectionTypes.nativeFinancing
				? allPageNames.alreadyFinancedPaymentCompletedPage
				: allPageNames.lateVehicleFullPaymentInfoWrapper;

		default:
			return null;
	}
}

// handle licensing upload
export async function handleFileUpload(fileFolder, fileName, fileData, userId) {
	if (!fileData) {
		return fileData;
	}
	// Get a reference to the storage service, which is used to create references in your storage bucket
	const storageRef = ref(storage, `/users/${userId}/${fileFolder}/${fileName}`);
	const uploadTask = uploadBytesResumable(storageRef, fileData);

	// Listen for state changes, errors, and completion of the upload.
	uploadTask.on(
		'state_changed',
		(snapshot) => {
			// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			// eslint-disable-next-line no-unused-vars
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			// eslint-disable-next-line default-case
			switch (snapshot.state) {
				case 'paused':
					break;
				case 'running':
					break;
			}
		},
		(error) => {
			// A full list of error codes is available at
			// https://firebase.google.com/docs/storage/web/handle-errors
			// eslint-disable-next-line default-case
			switch (error.code) {
				case 'storage/unauthorized':
					// User doesn't have permission to access the object
					createLog(
						'Storage-upload-error',
						`User doesn't have permission to access the object`,
						'ERROR',
						{
							buyerId: auth.currentUser.uid,
						},
					);
					break;
				case 'storage/canceled':
					// User canceled the upload
					createLog('Storage-upload-error', `User canceled the upload`, 'ERROR', {
						buyerId: auth.currentUser.uid,
					});
					break;
				case 'storage/unknown':
					// Unknown error occurred, inspect error.serverResponse
					createLog('Storage-upload-error', `Unknown error`, 'ERROR', {
						buyerId: auth.currentUser.uid,
					});
					break;
			}
		},
		() => {
			// Upload completed successfully, now we can get the download URL
			createLog('Storage-upload-success', `Sucessful file upload!`, 'INFO', {
				buyerId: auth.currentUser.uid,
			});
		},
	);
	const result = await uploadTask;
	const downloadURL = await getDownloadURL(result.ref);
	return downloadURL;
}

export async function loadSavedImage(filePath) {
	// Create a reference with an initial file path and name
	if (!filePath) return '';
	const pathReference = ref(storage, filePath);
	const imgUrl = await getDownloadURL(pathReference);
	return imgUrl;
}

export function deepCopyUpdate(stateVar, setterFunc, data, key = '') {
	let stateVarCopy = JSON.parse(JSON.stringify(stateVar));
	if (key) {
		stateVarCopy[key] = data;
	} else {
		stateVarCopy = data;
	}
	setterFunc(stateVarCopy);
}

export function deepCopy(obj) {
	if (typeof obj !== 'object' || obj === null) {
		// If obj is not an object, return it as is
		return obj;
	}

	if (Array.isArray(obj)) {
		// If obj is an array, create a new array and deep copy its elements
		const newArray = [];
		for (let i = 0; i < obj.length; i += 1) {
			newArray[i] = deepCopy(obj[i]);
		}
		return newArray;
	}

	// If obj is an object, create a new object and deep copy its properties
	const newObj = {};
	Object.keys(obj).forEach((key) => {
		newObj[key] = deepCopy(obj[key]);
	});
	return newObj;
}

export function handleCurrentPage(
	currentVehicle,
	currentPageMap,
	setCurrentPageMap,
	setContinueProgress,
	continueProgress,
	pageData,
	sectionKey,
) {
	if (currentVehicle) {
		if (!continueProgress) {
			try {
				saveCurrentPage(
					currentVehicle.placedOrderId,
					currentVehicle.id,
					pageData,
					sectionKey,
				);
				deepCopyUpdate(currentPageMap, setCurrentPageMap, pageData, sectionKey);
				createLog('Tracking-info', `Buyer moved to page ${pageData.pageName}`, 'INFO', {
					pageName: pageData.pageName,
					sectionKey,
					purchasedVehicleId: currentVehicle.id,
					placedOrderId: currentVehicle.placedOrderId,
					buyerID: auth.currentUser.uid,
				});
			} catch (error) {
				createLog(
					'Object-update-error',
					`Buyer could not move to page ${pageData.pageName}`,
					'CRITICAL',
					{
						pageName: pageData.pageName,
						sectionKey,
						purchasedVehicleId: currentVehicle.id,
						placedOrderId: currentVehicle.placedOrderId,
						buyerID: auth.currentUser.uid,
					},
				);
			}
		} else {
			setContinueProgress(false);
		}
	}
}

// Function to calculate amount already paid by the buyer in total (inclusive amts paid to OEM and Ekho)
export function calcAmountPaidByBuyer(vehicle) {
	let total = 0;
	// Add amounts paid before checkout
	if (vehicle.paymentInfo?.amountsPaidBeforeCheckout?.length > 0) {
		vehicle.paymentInfo.amountsPaidBeforeCheckout.forEach((prevPayment) => {
			total += prevPayment.amount || 0;
		});
	}
	// Add total amounts received => sum across reservation fees, down payments, and cash payments
	total += vehicle.paymentInfo?.totalAmountReceived || 0;
	return total;
}

// Slightly different from calcFullVehiclePrice
// Adds sales tax and minuses orderFee (which we assume is already paid)
// export function calcVehicleAmountOwed(vehicle) {
// 	let total = vehicle.totalPrice;
// 	if ('orderFee' in vehicle) {
// 		total -= vehicle.orderFee;
// 	}
// 	if (vehicle.paymentInfo?.amountsPaidBeforeCheckout?.length > 0) {
// 		vehicle.paymentInfo.amountsPaidBeforeCheckout.forEach((prevPayment) => {
// 			total -= prevPayment.amount;
// 		});
// 	}
// 	return total;
// }

// Fetch the current page of financing for when it loads first time
// export function getStartingPage(currentPageMap, activeSection) {
export function getStartingPage(currentPageMap, currentVehicle, activeSection, allPageNames) {
	// ------------- ALWAYS START ON KICKOFF PAGE ---------------- //
	// Check if financing section has a current page, and return it if it does
	// If it doesn't, return kickOffPage

	if (currentPageMap?.[activeSection]?.pageName) {
		// before currentPageMap?.activeSection?.pageName
		const { pageName } = currentPageMap[`${activeSection}`];
		return pageName;
	}
	// Return starting page if no current page
	return getSectionStartPage(currentVehicle, activeSection, allPageNames);
}

// Get the section that we start with by checking if activeSection is set in the map. If not, return sectionKeys.financing
export function getStartingSection(currentPageMap) {
	if (
		currentPageMap &&
		'activeSection' in currentPageMap &&
		currentPageMap.activeSection !== null
	) {
		return currentPageMap.activeSection;
	}
	return sectionKeys.financing;
}

// Converts a date to a moment object
export function dateToMoment(date) {
	// convert date to moment object
	// will work if date is a number (milliseconds since Unix) or a Date object
	return moment(date);
}

// Function used to check whether airdealer has received the financed amount from a 3rd party lender
export function otherLenderHasPaid() {
	// Not implementing until we know what this looks like
	return true;
}

// Function used to check whether airdealer has received the financed amount from native lender
export function nativeLenderHasPaid() {
	// const { paymentInfo } = currentVehicle;
	// Not implementing until we know what this looks like
	// const { nativeFinancingBuyersDecision } = currentVehicle;
	// const { nativeFinancingPayment } = paymentInfo;
	// if (nativeFinancingPayment.financedAmountReceived >= nativeFinancingBuyersDecision.loanAmount) {
	// 	return true;
	// }
	return false;
}

// Function used to return amount Airdealer still needs to receive from the buyer
export function nativeDownPaymentOutstanding(currentVehicle) {
	const { paymentInfo } = currentVehicle;
	// Not implementing until we know what this looks like
	const { nativeFinancingBuyersDecision } = currentVehicle;
	const { nativeFinancingPayment } = paymentInfo;
	const amountOutstanding =
		nativeFinancingBuyersDecision.downPayment -
		nativeFinancingPayment.downPaymentAmountReceived;
	if (amountOutstanding > 0) {
		return { moneyReceived: false, amountOutstanding };
	}
	return { moneyReceived: true, amountOutstanding };
}

// Checks the paymentInfo object to see if a document set has been completed
export function documentSetHasBeenSigned(paymentInfo, documentSetName) {
	if (!Object.hasOwn(paymentInfo, `${documentSetName}DocumentSet`)) {
		return false;
	}
	const docSetResObj = paymentInfo[`${documentSetName}DocumentSet`];
	if (!Object.hasOwn(docSetResObj, 'hasBeenCompleted')) {
		return false;
	}
	const { hasBeenCompleted } = docSetResObj;
	if (hasBeenCompleted === true) {
		return true;
	}
	return false;
}

export function formatConfirmationVals(
	origFormikValues,
	moneyFields,
	percentFields = [],
	phoneNumberFields = [],
	encryptedFields = [],
) {
	const toReturn = { ...origFormikValues };
	moneyFields.forEach((field) => {
		toReturn[field] = priceFormat(toReturn[field]);
	});
	if (percentFields) {
		percentFields.forEach((field) => {
			toReturn[field] = `${(toReturn[field] * 100).toFixed(2)}%`;
		});
	}
	if (phoneNumberFields) {
		phoneNumberFields.forEach((field) => {
			toReturn[field] = formatPhoneNumber(toReturn[field]);
		});
	}
	if (encryptedFields) {
		encryptedFields.forEach((field) => {
			toReturn[field] = '********';
		});
	}
	return toReturn;
}

function formatPhoneNumber(phoneNumber) {
	// Remove all non-digit characters from the input
	const digitsOnly = phoneNumber.toString().replace(/\D/g, '');

	// Format the phone number
	const formattedPhoneNumber = digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

	return formattedPhoneNumber;
}

export function generateCreditScoreOptions(financingProgram) {
	const creditScoreOptions = [];
	const { tiers } = financingProgram;
	// Loop through the objects within the tiers array. Each one has a creditScoreMax and creditScoreMin property.
	// Generate a string for each one and push it to the creditScoreOptions array as the text and set the tierNum as the value.
	tiers.forEach((tier) => {
		const { creditScoreMax, creditScoreMin, tierNum } = tier;
		const optionString = `${creditScoreMin} - ${creditScoreMax}`;
		creditScoreOptions.push({ text: optionString, value: tierNum });
	});
	// Insert a blank option at the beginning of the array that has the text 'Select Credit Score' and a value of null.
	creditScoreOptions.unshift({ text: 'Select', value: -1 });
	return creditScoreOptions;
}

export function getMinTermLengthDetailsFromTier(financingProgram, tierNum) {
	const { tiers } = financingProgram;
	// Find the tier object in the tiers array where the tierNum matches the tierNum passed in.
	const tier = tiers.find((tierObj) => tierObj.tierNum === tierNum);
	const { terms } = tier;
	// Find the key in the terms map that has the lowest value.
	const minTermLength = Math.min(...Object.keys(tier.terms));
	return terms[minTermLength];
}

// This function returns the second highest term length from the terms map in the tier object, if there are more than 2 terms.
export function getMaxTermLengthDetailsFromTier(financingProgram, tierNum) {
	const { tiers } = financingProgram;
	const tier = tiers.find((tierObj) => tierObj.tierNum === tierNum);
	const termLengths = Object.keys(tier.terms).sort((a, b) => b - a);
	if (termLengths.length <= 2) {
		return tier.terms[termLengths[0]]; // returns the term with max term length
	}
	const secondHighestTermLength = termLengths[1];
	return tier.terms[secondHighestTermLength];
}

export function fetchTermsFromTier(financingProgram, tierNum) {
	const { tiers } = financingProgram;
	const tier = tiers.find((tierObj) => tierObj.tierNum === tierNum);
	return tier.terms;
}

export function calculateLoanMonthlyPayment(apr, totalPriceAndTaxAndFees, downPayment, loanTerm) {
	const r = apr / 12.0;
	const loanAmount = totalPriceAndTaxAndFees - downPayment;
	const term = loanTerm;
	const monthlyPayment = (loanAmount * r * (1.0 + r) ** term) / ((1.0 + r) ** term - 1.0);
	return monthlyPayment;
}

export function generateTermLengthOptions(termsMap, newVehicles = true) {
	const termLengthOptions = [];
	// Loop through the terms map and push an object to the termLengthOptions array for each key/value pair.
	// The object should have the key as the text and the value as the value.
	const aprToCheck = newVehicles ? 'newVehicleApr' : 'usedVehicleApr';
	Object.keys(termsMap).forEach((key) => {
		const termDetails = termsMap[key];
		if (termDetails[aprToCheck] !== null) {
			termLengthOptions.push({ text: `${key} months`, value: key });
		}
	});
	return termLengthOptions;
}

// Helper function for getting the list of forms that need to be notarized in person for a given vehicle
export function getPhysicalNotarizedForms(stateForms, vehicle) {
	if (!vehicle.tarForms?.formsBuyerMustSign) {
		createLog(
			'Missing-vehicle-data',
			'Missing formsBuyerMustSign field in purchasedVehicle at function getPhysicalNotarizedForms',
			'CRITICAL',
			{ purchasedVehicleId: vehicle.id },
		);
		return [];
	}
	const { formsBuyerMustSign } = vehicle.tarForms;
	const physicalNotarizedForms = [];
	formsBuyerMustSign.forEach((formKey) => {
		if (!stateForms[formKey]) {
			createLog(
				'Missing-state-form',
				`Missing state form ${formKey} in stateDataMap at function getPhysicalNotarizedForms`,
				'CRITICAL',
				{ purchasedVehicleId: vehicle.id },
			);
			return;
		}
		const form = stateForms[formKey];
		if (form.notaryNeeded === NOTARY_FORM_TYPE.PHYSICAL) {
			physicalNotarizedForms.push(form.name);
		}
	});
	return physicalNotarizedForms;
}

// Helper function for getting the list of all forms that a buyer needs to sign in physical packet
export function getPhysicalForms(stateForms, vehicle) {
	if (!vehicle.tarForms?.formsBuyerMustSign) {
		createLog(
			'Missing-vehicle-data',
			'Missing formsBuyerMustSign field in purchasedVehicle at function getPhysicalForms',
			'CRITICAL',
			{ purchasedVehicleId: vehicle.id },
		);
		return [];
	}
	const physicalForms = [];
	const { formsBuyerMustSign } = vehicle.tarForms;
	formsBuyerMustSign.forEach((formKey) => {
		if (!stateForms[formKey]) {
			createLog(
				'Missing-state-form',
				`Missing state form ${formKey} in stateDataMap at function getPhysicalForms`,
				'CRITICAL',
				{ purchasedVehicleId: vehicle.id },
			);
			return;
		}
		const form = stateForms[formKey];
		// only include the form if it was not included in the esig packet
		if (!vehicle.esigs.documentsSignatures[form.key]) {
			physicalForms.push(form.name);
		}
	});
	physicalForms.push('Prepaid Shipping Label');
	return physicalForms;
}

export function isMobileDevice() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent,
	);
}

export const validateEmail = async (email, setErrorString) => {
	const emailSchema = Yup.object({
		email: Yup.string().email('Invalid email address').required('Email is required'),
	});
	try {
		await emailSchema.validate({ email });
		// If the validation is successful
		return true;
	} catch (error) {
		// If validation fails
		setErrorString(error.message);
		return false;
	}
};

export function getSupportedUseTypes(currentVehicle) {
	// For now only FL has supported use type of commercial and then only when not financing
	if (
		currentVehicle.registrationDetails.registrationState === 'FL' &&
		currentVehicle.paymentInfo.paymentSelection === paymentSelectionTypes.cash
	) {
		let toReturn = [];
		Object.values(SUPPORTED_USE_TYPES).forEach((value) => {
			toReturn.push({ value, text: value });
		});
		toReturn = toReturn.sort((a, b) => a.text.localeCompare(b.text));
		return toReturn;
	}
	return [
		{
			value: SUPPORTED_USE_TYPES.PERSONAL,
			text: SUPPORTED_USE_TYPES.PERSONAL,
		},
	];
}

export function createBestTimeUntilString(numDays) {
	let waitTime = '';
	// averageProductionTime is in days
	// Convert to weeks or months if necessary
	if (numDays >= 84) {
		waitTime = `${Math.round(numDays / 30)} months`;
	} else if (numDays >= 28) {
		waitTime = `${Math.round(numDays / 7)} weeks`;
	} else if (numDays === 0) {
		waitTime = `No wait time!`;
	} else {
		// account for 1 day vs multiple days
		waitTime = `${numDays} day${numDays > 1 ? 's' : ''}`;
	}
	return waitTime;
}

export function getMilesBetweenCoords(coord1, coord2) {
	const toRadians = (angle) => angle * (Math.PI / 180);

	const earthRadiusKm = 6371;

	const dLat = toRadians(coord2.lat - coord1.lat);
	const dLon = toRadians(coord2.lng - coord1.lng);

	const lat1 = toRadians(coord1.lat);
	const lat2 = toRadians(coord2.lat);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const distanceKm = earthRadiusKm * c;
	return distanceKm * 0.621371; // Convert km to miles
}

// A function passed to the Ekho Dealer Footer component that will be called when the user clicks on the Chat option
export const chatOnClick = () => {
	if (window.Intercom && window.Intercom('booted')) {
		// Check if Intercom is initialized
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	} else if (window.Intercom) {
		// If Intercom isn't initialized, initialize it and show the chat
		window.Intercom('boot', {
			api_base: 'https://api-iam.intercom.io',
			app_id: 'f1a94cfb',
			alignment: 'left',
			horizontal_padding: 50,
			vertical_padding: 50,
			hide_default_launcher: true,
			ekho_app: 'buyer_portal',
			oem_key: process.env.REACT_APP_OEM_KEY,
		});
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	}
};

export function getSupportedOwnerTypes(currentVehicle) {
	// For now only FL has supported owner type of business and then only when not financing
	if (
		currentVehicle.registrationDetails.registrationState === 'FL' &&
		currentVehicle.paymentInfo.paymentSelection === paymentSelectionTypes.cash
	) {
		let toReturn = [];
		Object.values(OWNER_TYPES).forEach((value) => {
			toReturn.push({ value, text: value });
		});
		toReturn = toReturn.sort((a, b) => a.text.localeCompare(b.text));
		return toReturn;
	}
	return [
		{
			value: OWNER_TYPES.INDIVIDUAL,
			text: OWNER_TYPES.INDIVIDUAL,
		},
	];
}

// This function takes in a fieldName, fieldObject, and detailsFormik object and returns the appropriate form element
export function createDynamicFormElement(
	fieldName,
	fieldObject,
	detailsFormik,
	stateAbbrev = null,
	tarProcess = null,
	mobileView = false,
	fixedFullWidth = false,
	incDefaultSelectVal = false,
) {
	let formElement = <> </>;
	if (!fieldObject) {
		createLog(
			'createDynamicFormElement',
			`No fieldObject specified for field ${fieldName}`,
			'ERROR',
			{ state: stateAbbrev },
		);
		return (
			<div className={fixedFullWidth ? 'col-12' : 'col-12 col-sm-6'} key={fieldName}>
				<FormGroup id={fieldName} label={fieldName}>
					<Input
						size='lg'
						id={fieldName}
						placeholder=''
						autoComplete=''
						required
						onChange={detailsFormik.handleChange}
						onBlur={detailsFormik.handleBlur}
						value={detailsFormik.values[fieldName]}
						isValid={detailsFormik.isValid}
						isTouched={detailsFormik.touched[fieldName]}
						invalidFeedback={detailsFormik.errors[fieldName]}
					/>
				</FormGroup>
			</div>
		);
	}
	switch (fieldObject.type) {
		case INPUT_TYPES.TITLE_DIVIDER:
			return (
				<div className='col-12' key={fieldName}>
					<CardLabel>
						<CardTitle tag='h5' className='h5' style={{ fontWeight: '600' }}>
							{fieldObject.title || 'New section'}
						</CardTitle>
					</CardLabel>
				</div>
			);
		case INPUT_TYPES.SELECT: {
			let list = fieldObject?.list || [];
			// Check if the default 'Select' option is already present
			const defaultOption = { value: null, text: 'Select' };
			if (
				incDefaultSelectVal &&
				!list.some(
					(option) =>
						option.value === defaultOption.value && option.text === defaultOption.text,
				)
			) {
				list = [defaultOption, ...list];
			}
			formElement = (
				<Select
					size='lg'
					name={fieldName}
					placeholder=''
					ariaLabel=''
					required={'required' in fieldObject ? fieldObject.required : true}
					list={list}
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
		}
		case INPUT_TYPES.CHECK:
			formElement = (
				<Checks
					label={fieldObject?.checkLabel}
					name={fieldName}
					required={'required' in fieldObject ? fieldObject.required : true}
					checked={detailsFormik.values[fieldName]}
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
		case INPUT_TYPES.CHECK_SELECT:
			formElement = (
				<Select
					size='lg'
					name={fieldName}
					required={'required' in fieldObject ? fieldObject.required : true}
					list={[
						{ value: null, text: 'Select' },
						{ value: true, text: 'Yes' },
						{ value: false, text: 'No' },
					]}
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
		case INPUT_TYPES.TEXT_INPUT:
			formElement = (
				<Input
					size='lg'
					placeholder=''
					type={fieldObject.inputType || 'text'}
					autoComplete={fieldObject.autoComplete || null}
					mask={fieldObject.mask || null}
					required={'required' in fieldObject ? fieldObject.required : true}
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
		case INPUT_TYPES.DATE:
			formElement = (
				<Input
					size='lg'
					placeholder='MM/DD/YYYY'
					type={mobileView ? 'text' : 'date'}
					required={'required' in fieldObject ? fieldObject.required : true}
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
		default:
			formElement = (
				<Input
					size='lg'
					placeholder=''
					type='text'
					required
					onChange={detailsFormik.handleChange}
					onBlur={detailsFormik.handleBlur}
					value={detailsFormik.values[fieldName]}
					isValid={detailsFormik.isValid}
					isTouched={detailsFormik.touched[fieldName]}
					invalidFeedback={detailsFormik.errors[fieldName]}
				/>
			);
			break;
	}

	let label = fieldObject?.checkLabel || fieldObject.label;
	if (
		tarProcess === TAR_PROCESS_ENUM.AUTOMATED_TITLING ||
		tarProcess === TAR_PROCESS_ENUM.ASSISTED_TITLING
	) {
		label = label.replace('registration', 'titling').replace('register', 'title');
	}
	return (
		<div className={fixedFullWidth ? 'col-12' : 'col-12 col-sm-6'} key={fieldName}>
			<FormGroup id={fieldName} label={label} formText={fieldObject?.text}>
				{formElement}
			</FormGroup>
		</div>
	);
}

export function genDynamicInitialValuesForSurveyFormik(surveyQuestions) {
	const initialValues = {};
	surveyQuestions.forEach((question) => {
		initialValues[question.id] = question.defaultValue ? question.defaultValue : '';
	});
	return initialValues;
}

/* --------- OUTSIDE THE SCOPE OF THIS VERSION 2 PROJECT --------- */
// // Function for determining necessary components of the commencement modal
// // eslint-disable-next-line consistent-return
// export function genCommencementModalSections(currentVehicle) {
// 	if (
// 		currentVehicle.typeOfOrder === TYPE_OF_ORDER_ENUM.BUILD_TO_ORDER ||
// 		currentVehicle.typeOfOrder === TYPE_OF_ORDER_ENUM.PREORDER
// 	) {
// 		return [COMMENCEMENT_MODAL_SECTIONS.INFO_COLLECT, COMMENCEMENT_MODAL_SECTIONS.SIGNOFF];
// 	}
// 	// LOGIC FOR OTHER COMMENCEMENT MODAL SECTION CONFIGURATIONS
// }

// Function for determining all potentially relevant sections for vehicle details page
// Note: logic for this function should not be affected by choices made within buyer portal
// eslint-disable-next-line no-unused-vars
export function genVehicleDetailsSections(currentVehicle, oemProfileProps, stateObject) {
	const sections = [];
	// 1. Define sections to exclude! Always exclude delivery details for now...
	const sectionsToExclude = ['deliveryDetails'];
	const { paymentConfig } = oemProfileProps;
	// If paid in full, then don't show financing section
	if (currentVehicle.paymentInfo.paymentSelection === paymentSelectionTypes.checkoutFullPayment) {
		sectionsToExclude.push(sectionKeys.financing);
		sectionsToExclude.push(sectionKeys.payment);
	}
	if (!paymentConfig[PAYMENT_TYPES.LATE_REMAINING_BALANCE]) {
		sectionsToExclude.push(sectionKeys.payment);
	}

	// figure out if we're going to exclude insurance section. Exclude if insurance is not required and no policies available
	const oemVehicle = oemProfileProps.vehicles[currentVehicle.productId];
	const tarProcess = determineTarProcess(currentVehicle);
	let vehicleType;
	if (currentVehicle.vehicleType) {
		vehicleType = currentVehicle.vehicleType;
	} else {
		vehicleType = oemVehicle.vehicleType;
	}
	const insuranceReq = getInsuranceReqEnum(currentVehicle, vehicleType, stateObject);
	let vehicleHasInsPoliciesAvail = false;
	if (oemVehicle.insurancePoliciesAvailable && oemVehicle.insurancePoliciesAvailable.length > 0) {
		vehicleHasInsPoliciesAvail = true;
	}
	if (insuranceReq === insuranceReqTypes.insuranceNotRequired && !vehicleHasInsPoliciesAvail) {
		sectionsToExclude.push(sectionKeys.insurance);
	}

	// Build up sections array
	let currIdx = 0;
	// eslint-disable-next-line no-restricted-syntax
	for (const key of ALL_SECTION_ORDER) {
		const value = getSectionDataMap(tarProcess, key);
		if (!sectionsToExclude.includes(key)) {
			value.highLevelInfo.key = key;
			value.highLevelInfo.idx = currIdx;
			sections.push(value.highLevelInfo);
			currIdx += 1;
		}
	}
	return sections;
}

export function sectionIsComplete(thisSectionKey, activeSectionKey, sections) {
	// Loop through the keys in sections; if sectionKey appears appears before currentSectionKey, then return true
	// Otherwise, return false
	if (thisSectionKey === activeSectionKey) return false;
	let thisSectionHasBeenCompleted = false;
	// loop through sections
	for (let i = 0; i < sections.length; i += 1) {
		const { key } = sections[i];
		if (key === thisSectionKey) {
			thisSectionHasBeenCompleted = true;
		}
		if (key === activeSectionKey) {
			return thisSectionHasBeenCompleted;
		}
	}
	return false;
}

export function checkBuyerIsFinancing(currentVehicle) {
	if (currentVehicle?.paymentInfo?.paymentSelection === paymentSelectionTypes.nativeFinancing) {
		return true;
	}
	return false;
}

// Function for determining which sections are no longer relevant for a vehicle => grey them out
export function genGreyedOutVehicleSections(currentVehicle, sections) {
	// If financing, then grey out final payment!
	const buyerIsFinancing = checkBuyerIsFinancing(currentVehicle);
	if (buyerIsFinancing && sections.find((x) => x.key === sectionKeys.payment)) {
		return [sectionKeys.payment];
	}

	// TO DO: If cash in full at beginning of BP, then grey out payment sections
	if (currentVehicle.paymentInfo.paymentSelection === paymentSelectionTypes.cashEarly) {
		return [sectionKeys.payment];
	}
	// TO DO: add other grey out logic

	// DEFAULT: No greyed out sections
	return [];
}

// Helper function for updating front end vars (local page map, active section key, active page name, step num) to match backend updates
// Use: helpful in relocation flow logic since updates are propagated from the backend
export async function updateFEFromPageMap(
	updatedPageMap,
	currentPageMap,
	setCurrentPageMap,
	setActiveSectionKey,
	setActivePageName,
	setStepNum,
) {
	// lodash comparison checking if updatedpageMap and currentPageMap are different
	if (!_.isEqual(updatedPageMap, currentPageMap)) {
		// ORDERING IS IMPORTANT HERE
		setActiveSectionKey(updatedPageMap.activeSection);
		if (updatedPageMap[updatedPageMap.activeSection] !== null) {
			setActivePageName(updatedPageMap[updatedPageMap.activeSection].pageName);
		} else {
			const kickOffPageName = `${updatedPageMap.activeSection}KickOffPage`;
			setActivePageName(kickOffPageName);
		}
		deepCopyUpdate(currentPageMap, setCurrentPageMap, updatedPageMap);
		setStepNum(updatedPageMap[updatedPageMap.activeSection]?.stepNum || 1);
	}
}

// Helper function for determining whether a page in a relocation flow should have a disabled back button
export function disableRelocBack(relocPageBehavior) {
	if (relocPageBehavior === null) return false;

	if (relocPageBehavior.initPage || relocPageBehavior.backButtonDisabled) {
		return true;
	}
	return false;
}

// Helper function for checking / fetching reloc page behavior for a given page
export function checkRelocPageBehavior(purchasedVehicle, pageName) {
	let relocPageBehavior = null;
	if (
		purchasedVehicle.relocations?.length > 0 &&
		purchasedVehicle.relocations[0].relocPageBehavior[pageName]
	) {
		relocPageBehavior = purchasedVehicle.relocations[0].relocPageBehavior[pageName];
	}
	return relocPageBehavior;
}

// Standard function for completeing a relocation flow
export async function completeRelocFlow(data) {
	// 0. Destructure data
	const {
		purchasedVehicleId,
		relocId,
		currentPageMap,
		setCurrentPageMap,
		setActiveSectionKey,
		setActivePageName,
		setStepNum,
	} = data;

	// 1. Delete reloc flow (and any associated modal)
	const { updatedPageMap } = await deleteRelocation(purchasedVehicleId, relocId);

	// 2. Update current page map, active page/section keys, and step num to match backend updates
	updateFEFromPageMap(
		updatedPageMap,
		currentPageMap,
		setCurrentPageMap,
		setActiveSectionKey,
		setActivePageName,
		setStepNum,
	);
}

// Standard function for beginning a relocation flow
export async function startRelocationFlow(data) {
	// 0. Destructure data
	const {
		purchasedVehicleId,
		relocationName,
		relocPosition,
		modalId,
		metadata,
		currentPageMap,
		setCurrentPageMap,
		setActiveSectionKey,
		setActivePageName,
		setStepNum,
	} = data;

	// 1. Perform relocation and make backend objects
	const { updatedPageMap } = await performRelocation(
		purchasedVehicleId,
		relocationName,
		relocPosition,
		modalId,
		metadata,
	);

	// 2. update FE state vars to align with new page map
	await updateFEFromPageMap(
		updatedPageMap,
		currentPageMap,
		setCurrentPageMap,
		setActiveSectionKey,
		setActivePageName,
		setStepNum,
	);
}

export async function acknowledgeAlertModal(data) {
	// 0. Destructure data
	const { modalId, purchasedVehicleId, toggleModal } = data;
	// 1. Delete modal (non-blocking)
	deleteModal(purchasedVehicleId, modalId);
	// 2. Toggle away
	toggleModal(false);
}

export async function submitSurveyModal(data) {
	// 0. Destructure data
	const { modalId, purchasedVehicleId, oem, surveyFormikRef, toggleModal } = data;
	// 1. Submit survey
	const surveyFormikVals = surveyFormikRef.current.getFormikValues();
	const errors = await surveyFormikRef.current.validateForm();
	// early stop bc validiation errors
	if (Object.keys(errors).length > 0) return;
	const surveyData = {};

	Object.entries(surveyFormikVals).forEach(([surveyKey, val]) => {
		const surveyQuestion = oem.surveyQuestions.find((x) => x.id === surveyKey);
		// convert the answer to the right type => default is string
		let parsedAnswer = val;
		if (surveyQuestion.answerType === 'number') {
			parsedAnswer = parseFloat(val);
		} else if (surveyQuestion.answerType === 'boolean') {
			if (val === 'true') {
				parsedAnswer = true;
			} else if (val === 'false') {
				parsedAnswer = false;
			} else {
				parsedAnswer = null;
			}
		} else if (surveyQuestion.answerType === 'date' || surveyQuestion.answerType === 'string') {
			parsedAnswer = String(val);
		}
		surveyData[surveyKey] = {
			answer: parsedAnswer,
			question: surveyQuestion.question,
		};
	});
	await submitSurveyData(surveyData, purchasedVehicleId);

	// 2. Delete modal (non-blocking)
	deleteModal(purchasedVehicleId, modalId);
	// 3. Toggle away
	toggleModal(false);
}

export async function acknowledgeEkhoMustTARAlertModal(data) {
	const { purchasedVehicle, oemVehicle, setStepNum, setActivePageName, prevStepNum, nextPage } =
		data;
	const { tarProcess, registrationState } = purchasedVehicle.registrationDetails;
	const onlyTitlingOptions = [
		TAR_PROCESS_ENUM.AUTOMATED_TITLING,
		TAR_PROCESS_ENUM.ASSISTED_TITLING,
	];
	const onlyTitling = onlyTitlingOptions.includes(tarProcess);
	const updatedTarProcess = onlyTitling
		? TAR_PROCESS_ENUM.AUTOMATED_TITLING
		: TAR_PROCESS_ENUM.AUTOMATED;
	const updatedTarAgent = oemVehicle.registrationConfig[updatedTarProcess].tarAgent;
	// We need to check the validity of the zip in case we're missing the last four digits.
	// This is necessary for automated titling, but not for assisted.
	// If the zip is invalid, we show an alert modal for the user to fix it and then generate reg fees.
	const zipWithExtRegex = /^\d{5}-\d{4}$/;
	if (zipWithExtRegex.test(purchasedVehicle.registrationDetails.registrationAddress.zip)) {
		await genRegFees(
			purchasedVehicle.placedOrderId,
			purchasedVehicle.id,
			purchasedVehicle.productId,
			purchasedVehicle.oemId,
			registrationState,
		);
	}
	await saveGenericData(purchasedVehicle.id, 'registrationDetails.tarAgent', updatedTarAgent);
	await saveGenericData(purchasedVehicle.id, 'registrationDetails.tarProcess', updatedTarProcess);
	await acknowledgeAlertModal(data);
	setStepNum(prevStepNum + 1);
	setActivePageName(nextPage);
}
export function moveToCorrectPage(
	currentPageMap,
	sectionKey,
	setContinueProgress,
	setStepNum,
	setActivePageName,
) {
	// move to completed page if finished || skip to actual page if in financing
	if (
		(currentPageMap[sectionKey] !== null &&
			currentPageMap[sectionKey].pageName.endsWith(COMPLETED_PAGE_SUFFIX)) ||
		(sectionKey === sectionKeys.financing && currentPageMap[sectionKey] !== null)
	) {
		setContinueProgress(true);
		setStepNum(currentPageMap[sectionKey].stepNum);
		setActivePageName(currentPageMap[sectionKey].pageName);
	}
	// otherwise, show kickoff page
	else {
		setStepNum(1);
		// Dynamically creates the correct kickOff page name
		const kickOffPageName = `${sectionKey}KickOffPage`;
		setActivePageName(kickOffPageName);
	}
}

export function getNextSection(sectionKey, sections, greySections) {
	const currSection = sections.find((x) => x.key === sectionKey);
	// If last element, return null
	if (currSection.idx === sections.length - 1) {
		return null;
	}
	// loop through sections array starting at currSection.idx and find the next section that is not greyed out
	let nextSectionIdx = currSection.idx + 1;
	let nextSection = sections[nextSectionIdx];
	while (greySections.includes(nextSection?.key)) {
		nextSectionIdx += 1;
		nextSection = sections[nextSectionIdx];
	}
	return nextSection;
}

// Function for sending out appropriate segment events and updating financing action item once a buyer has completed identity verification in financing
export async function handleCreditAppSubmissionTasks(data) {
	// buyer can submit: financing action item = SUBMIT_CREDIT_APP_AND_REPORT_DECISION, segment event = track_credit_application_submitted_by_buyer
	const { canSubmitCreditAppData, currentVehicle } = data;
	const { canSubmitCreditApp } = canSubmitCreditAppData;
	if (canSubmitCreditApp) {
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.SUBMIT_CREDIT_APP_AND_REPORT_DECISION,
		);
	}
	// buyer can't submit: financing action item = WAIT_FOR_SUBMISSION_WINDOW, segment event = track_credit_application_created_by_buyer
	else {
		const { daysUntilSubmission } = canSubmitCreditAppData;
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.WAIT_FOR_SUBMISSION_WINDOW,
			daysUntilSubmission,
		);
	}
}

export function calcEarliestSubmissionDate(estDeliveryDay) {
	const earliestSubmissionDate = moment(estDeliveryDay)
		.subtract(MAX_WEEKS_BEFORE_READY, 'weeks')
		.format('MMMM DD, YYYY');
	return earliestSubmissionDate;
}

export function openChat() {
	// Show or hide the intercom messenger.
	if (window.Intercom && window.Intercom('booted')) {
		// Check if Intercom is initialized
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	} else if (window.Intercom) {
		// If Intercom isn't initialized, initialize it and show the chat
		window.Intercom('boot', {
			api_base: 'https://api-iam.intercom.io',
			app_id: 'f1a94cfb',
			alignment: 'left',
			horizontal_padding: 75,
			vertical_padding: 65,
			hide_default_launcher: true,
			ekho_app: 'Buyer Portal',
		});
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	}
}

// --- DUPLICATES FROM BACKEND --- //
export function isResPayment(type) {
	return PAYMENT_DESCRIPTION_TYPES.RESERVATION_PAYMENT.paymentTypes.includes(type);
}
export function isBalancePayment(type) {
	return PAYMENT_DESCRIPTION_TYPES.REMAINING_BALANCE_PAYMENT.paymentTypes.includes(type);
}
export function isAddDownPayment(type) {
	return PAYMENT_DESCRIPTION_TYPES.LOAN_ADDITIONAL_DOWN.paymentTypes.includes(type);
}

export function getPaymentInfoStr(paymentType) {
	if (isBalancePayment(paymentType)) {
		return 'paymentInfo.cashPayment';
	}
	if (isResPayment(paymentType)) {
		return 'paymentInfo.reservationPayment';
	}
	if (isAddDownPayment(paymentType)) {
		return 'paymentInfo.nativeFinancing.downPayment';
	}
	// other payment types (like lender payment) don't have an associated payment info object on PV => return null
	return null;
}

export function toPercentageString(num) {
	return `${(num * 100).toFixed(2)}%`;
}

export function genEarlyBalancePaymentDiscountObj(vehicle, oem) {
	const fullPaymentDiscountPerc =
		oem.paymentConfig[PAYMENT_TYPES.EARLY_REMAINING_BALANCE]?.fullPaymentDiscountPerc || 0;
	const newDiscount = vehicle.basePrice * fullPaymentDiscountPerc;
	return {
		amount: newDiscount,
		type: DISCOUNT_TYPES.PERCENTAGE_DISCOUNT,
		name: `Buyer portal full payment discount (${toPercentageString(fullPaymentDiscountPerc)})`,
		dateApplied: Date.now(),
	};
}

export function moveToNextSectionFunc(data) {
	const {
		nextSectionKey,
		setActiveSectionKey,
		setActivePageName,
		setStepNum,
		nextPage,
		placedOrderId,
		vehicleId,
		currentPageMap,
		setCurrentPageMap,
	} = data;
	// Update section
	setActiveSectionKey(nextSectionKey);
	setStepNum(1);
	// saveActiveSection(placedOrderId, nextSectionKey, vehicleId);
	deepCopyUpdate(currentPageMap, setCurrentPageMap, nextSectionKey, 'activeSection');

	// Update page
	const pageData = {
		pageName: nextPage,
		stepNum: 1,
	};
	setActivePageName(nextPage);
	// Save both current page and active section to backend
	saveCurrentPage(placedOrderId, vehicleId, pageData, nextSectionKey, nextSectionKey); // need to pass in nextSectionKey twice because of how we're saving the page
	deepCopyUpdate(currentPageMap, setCurrentPageMap, pageData, nextSectionKey);
}

// Helper function which generates a dynamic footer that moves buyer to the next section
export function genDynamicNextSectionFooter(
	nextSection,
	moveNextSectionData,
	hasBackButton,
	backButtonText,
	prevPage,
	customFunc = null,
	customFuncData = {},
	hasNextSectionButton = nextSection !== null,
) {
	// Validate moveNextSectionData
	const nextSectionDataKeys = [
		'nextPage',
		'nextSectionKey',
		'setActiveSectionKey',
		'setActivePageName',
		'setStepNum',
		'placedOrderId',
		'vehicleId',
		'currentPageMap',
		'setCurrentPageMap',
	];
	// Loop through nextSectionDataKeys and make sure they're all in moveNextSectionData and that the value is truthy - otherwise, throw an error
	nextSectionDataKeys.forEach((key) => {
		if (!(key in moveNextSectionData) || !moveNextSectionData[key]) {
			throw new Error(`Missing or invalid ${key} in moveNextSectionData`);
		}
	});
	// Get next section
	return (
		<StandardFooter
			hasNextSectionButton={hasNextSectionButton}
			nextSectionButtonText={`Continue to ${nextSection?.title}`}
			moveToNextSectionFunc={async (data) => {
				if (customFunc) {
					await customFunc(customFuncData);
				}
				moveToNextSectionFunc(data);
			}}
			moveToNextSectionData={moveNextSectionData}
			hasBackButton={hasBackButton}
			backButtonText={backButtonText}
			prevPage={prevPage}
			setStepNum={moveNextSectionData.setStepNum}
			setActivePageName={moveNextSectionData.setActivePageName}
			stepNum={moveNextSectionData.stepNum}
		/>
	);
}

// Helper function for generating a footer for terminating a relocation flow
export function genTerminateRelocFooter(terminateRelocData) {
	// Validate terminateRelocData
	const terminateRelocDataKeys = [
		'setActiveSectionKey',
		'setActivePageName',
		'setStepNum',
		'stepNum',
		'vehicleId',
		'relocId',
		'currentPageMap',
		'setCurrentPageMap',
		// 'backButtonData', => not required
	];
	// Loop through terminateRelocDataKeys and make sure they're all in terminateRelocData and that the value is truthy - otherwise, throw an error
	terminateRelocDataKeys.forEach((key) => {
		if (!(key in terminateRelocData) || !terminateRelocData[key]) {
			throw new Error(`Missing or invalid ${key} in terminateRelocData`);
		}
	});

	return (
		<StandardFooter
			hasRelocCompleteButton
			setActiveSectionKey={terminateRelocData.setActiveSectionKey}
			setStepNum={terminateRelocData.setStepNum}
			setActivePageName={terminateRelocData.setActivePageName}
			stepNum={terminateRelocData.stepNum}
			onClickFunction={completeRelocFlow}
			onClickFunctionData={{
				purchasedVehicleId: terminateRelocData.vehicleId,
				relocId: terminateRelocData.relocId,
				currentPageMap: terminateRelocData.currentPageMap,
				setCurrentPageMap: terminateRelocData.setCurrentPageMap,
				setActivePageName: terminateRelocData.setActivePageName,
				setActiveSectionKey: terminateRelocData.setActiveSectionKey,
				setStepNum: terminateRelocData.setStepNum,
			}}
			// Back button data
			hasBackButton={!!terminateRelocData.backButtonData?.hasBackButton}
			backButtonText={terminateRelocData.backButtonData?.backButtonText}
			prevPage={terminateRelocData.backButtonData?.prevPage}
		/>
	);
}

export function getNextPageDocumentsSignatures(currentVehicle, allPageNames) {
	const tarProcess = determineTarProcess(currentVehicle);
	// if self-registering or self-titling, or none, go to completed page
	if (
		tarProcess === TAR_PROCESS_ENUM.ASSISTED ||
		tarProcess === TAR_PROCESS_ENUM.ASSISTED_TITLING ||
		tarProcess === TAR_PROCESS_ENUM.NONE
	) {
		return allPageNames.documentsCompletedPage;
	}
	// if buyer uploads exist, return first buyer upload
	if (currentVehicle.tarForms?.buyerUploads?.length > 0) {
		return currentVehicle.tarForms.buyerUploads[0];
	}
	// if esign, return tar signatures page
	if (currentVehicle.sigWorkflow === SIG_WORKFLOW_TYPE.ESIGN) {
		return allPageNames.tarSignatures;
	}
	// if not esign, return download buyer reg forms
	return allPageNames.downloadBuyerRegForms;
}

export function getPrevPageTarSignatures(currentVehicle, allPageNames) {
	// if buyer uploads exist, return last buyer upload
	if (currentVehicle.tarForms?.buyerUploads?.length > 0) {
		return currentVehicle.tarForms.buyerUploads[
			currentVehicle.tarForms.buyerUploads.length - 1
		];
	}
	const buyerIsFinancing = checkBuyerIsFinancing(currentVehicle);
	if (buyerIsFinancing) {
		return allPageNames.documentsAndSignaturesStartingPage;
	}
	return allPageNames.documentsSignatures;
}

// Helper function that determines which document sets are relevant for a given vehicle.
// Please note: this is only accurate after tar manual intervation has been completed (for automated T&R orders)
export function getRelevantDocumentSets(currentVehicle) {
	const documentSets = [SIGNATURE_PAGE_NAMES.documentsSignatures];
	const buyerIsFinancing = checkBuyerIsFinancing(currentVehicle);
	// Add native financing if relelvant
	if (buyerIsFinancing) {
		documentSets.push(SIGNATURE_PAGE_NAMES.nativeFinancingSignatures);
	}
	// Add esign if it exists
	const tarProcess = determineTarProcess(currentVehicle);
	if (
		(tarProcess === TAR_PROCESS_ENUM.AUTOMATED ||
			tarProcess === TAR_PROCESS_ENUM.AUTOMATED_TITLING) &&
		currentVehicle.sigWorkflow === SIG_WORKFLOW_TYPE.ESIGN
	) {
		documentSets.push('tarSignatures');
	}
	return documentSets;
}

// helper function for checking if next page should be purchase agreement signature in payment scenario edge case
export function needsPurchaseAgreementSignatures(vehicle, isBuyerFinancingOverride = null) {
	const buyerIsFinancing =
		isBuyerFinancingOverride === null
			? checkBuyerIsFinancing(vehicle)
			: isBuyerFinancingOverride;
	const paSigned =
		vehicle?.esigs?.purchaseAgreementSignatures?.documentSetMetadata?.buyerHasSigned;
	if (!buyerIsFinancing && !paSigned) {
		return true;
	}
	return false;
}

// helper function to retrieve next financing page
export function retrieveNextFinancingPage(vehicle, allPageNames) {
	const { lenderDecision } = vehicle.paymentInfo.nativeFinancing;
	if (lenderDecision !== null) {
		if (lenderDecision.decision === creditAppDecisionTypes.rejected) {
			return allPageNames.creditAppRejected;
		}
		if (lenderDecision.decision === creditAppDecisionTypes.accepted) {
			return allPageNames.nativeFinancingOfferDecisionCollect;
		}
		if (lenderDecision.decision === creditAppDecisionTypes.needMoreInfo) {
			return allPageNames.nativeFinancingChoiceCollect;
		}
		if (lenderDecision.buyerOfferDecision === creditAppDecisionTypes.counterOffer) {
			return allPageNames.nativeFinancingOfferDecisionCollect;
		}
	}
	return allPageNames.nativeFinancingChoiceCollect;
}
