// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EkhoDealerFooter, Card, CardBody } from '@ekhodealer/ekho-common/components';
import MobileViewContext from '../../../contexts/mobileViewContext';

const VehicleSectionSelectContainer = ({ children }) => {
	const { mobileView } = useContext(MobileViewContext);

	return (
		<Card
			shadown='none'
			style={{
				height: '100%',
				borderRadius: '0',
				marginBottom: '0',
				minHeight: 'min-content',
				// maxWidth: stacked ? '100%' : `${CHECKOUT_INFO_MAX_WIDTH}px`,
				padding: '0',
			}}>
			<CardBody
				style={{
					scrollBehavior: !mobileView ? 'smooth' : '',
					maxHeight: '100%',
					overflowY: !mobileView ? 'auto' : '',
					minHeight: '880px',
					paddingLeft: '3rem',
					paddingRight: '3rem',
					paddingTop: '3rem',
					paddingBottom: '3rem',
				}}>
				{children}
			</CardBody>
			{!mobileView && (
				<>
					<EkhoDealerFooter stacked={false} linkFourText='' />
					<div style={{ height: '2rem' }} />
				</>
			)}
		</Card>
	);
};

VehicleSectionSelectContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default VehicleSectionSelectContainer;
